import { updateDataLayer } from '../../lib/datalayer';

function emitTabCoursesEvent(courseCode: string): void {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'navigation',
    eventAction: 'click text',
    eventLabel: `student projects::${courseCode}`
  });
}

function emitViewDetailsEvent(): void {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'navigation',
    eventAction: 'click button',
    eventLabel: 'student projects::view project details'
  });
}

export { emitTabCoursesEvent, emitViewDetailsEvent };
