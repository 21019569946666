interface Pictures {
  [key: string]: string[];
}

export default function GetStudentProjectsImage(
  course: string,
  index: number
): string {
  const pictures: Pictures = {
    web: [
      '/assets/student-projects/ignacio-moreno-project.png',
      '/assets/student-projects/adrian-del-arco-project.png'
    ],
    data: [
      '/assets/student-projects/alvaro-fragoso-project.png',
      '/assets/student-projects/ingrid-munne-project.png'
    ],
    uxui: [
      '/assets/student-projects/laura-amber-project.png',
      '/assets/student-projects/chris-peterson-project.png'
    ],
    cysec: [
      '/assets/student-projects/cysec-project-1.png',
      '/assets/student-projects/cysec-project-2.png'
    ],
    rmt: [
      '/assets/student-testimonials/testimonial-1.jpg',
      '/assets/student-testimonials/testimonial-2.jpg'
    ]
  };
  return pictures[course][index];
}
