import styled, { css } from 'styled-components';
import config from '../config';
import { Link } from 'gatsby';

const Courses = styled.article`
  display: flex;

  h3 {
    width: 33%;
    border-bottom: 0.1rem solid ${config.palette.whiteTransparent('0.2')};
    color: ${config.palette.whiteTransparent('0.2')};
    font-size: 1.6rem;
    line-height: 150%;
    text-align: center;
    cursor: pointer;
  }
  h3.active {
    color: ${config.palette.white};
    border-color: ${config.palette.white};
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    display: -webkit-box;
    overflow-x: scroll;

    h3 {
      width: 60%;
    }
  }
`;

const StudentCards = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Card = styled('article')<{ background: string }>`
  width: calc(50% - 3.2rem);
  border-radius: 0.6rem;
  background-image: url(${props => props.background});
  box-shadow: 0 0.8rem 1.6rem rgba(0, 0, 0, 0.06);
  padding-top: 18.8rem;
  margin-top: 3.4rem;
  background-size: cover;
  background-repeat: no-repeat;

  & > div {
    background-color: ${config.palette.white};
    padding: 1.6rem;
    font-size: 1.4rem;
    line-height: 171%;
    color: ${config.palette.darkBlueTransparent('0.6')};

    b {
      display: block;
    }
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    width: 100%;
    padding-top: 22rem;
  }
`;

const ButtonLink = styled.a`
  background-color: ${config.palette.lightBlue};
  border-radius: 0 0 0.6rem 0.6rem;
  color: ${config.palette.white};
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  line-heiht: 140%;
  padding: 1.8rem 0;
  text-align: center;
  text-decoration: none;
  width: 100%;
`;

const Pagination = styled.section`
  display: none;

  @media (max-width: ${config.resolutions.mobileL}) {
    width: 100%;
    display: flex;
    padding: 2rem 0;
    margin-top: 2.4rem;
  }
`;

const Swipeable = styled.div`
  display: flex;

  svg:first-child {
    margin-right: 2.4rem;
  }

  path {
    fill: ${config.palette.white};
  }
`;

const PaginationItems = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 6rem;

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-right: 2.8rem;
  }
`;

const CardIndex = styled('article')<{ totalItems: number }>`
  width: ${props => 100 / props.totalItems}%;
  height: 0.2rem;
  margin-right: 0.4rem;
  background-color: ${config.palette.whiteTransparent('0.4')};

  &.active {
    background-color: ${config.palette.white};
  }
`;

const LayoutStudentProjects = css`
  padding-top: 6.4rem;
  padding-bottom: 6.4rem;
  background-color: ${config.palette.darkBlue};

  & > h2 {
    color: ${config.palette.white};
    margin-bottom: 3.4rem;
  }
`;

export {
  Courses,
  StudentCards,
  Card,
  ButtonLink,
  Pagination,
  Swipeable,
  PaginationItems,
  CardIndex,
  LayoutStudentProjects
};
