import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import withI18next from '../../i18n/TemplateI18n';
import GetStudentProjectsImage from '../../lib/student-projects-images';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import StudentProjectsAlumni from '../../components/StudentProjectsAlumni';
import Footer from '../../components/Footer';
import AlumniProps from '../../interface/template';
import useBlogArticles from '../../hooks/useBlogArticles';
import BlogArticles from '../../components/BlogArticles';
import Banner from '../../components/Banner';
import useUserLocation from '../../hooks/useUserLocation';

const Alumni: React.SFC<any> = (props: AlumniProps) => {
  const { pageContext } = props;
  const { locale, pageName } = pageContext;
  const { t } = useTranslation();

  const alumniArticles = useBlogArticles('alumni');
  const { userLocation } = useUserLocation();

  const studentProjects = [
    {
      course: 'web',
      image: GetStudentProjectsImage('web', 0),
      name: t(`course-web-dev-campus:web.studentProjects.projects.01.title`),
      text: t(
        `course-web-dev-campus:web.studentProjects.projects.01.description`
      ),
      url: t(`course-web-dev-campus:web.studentProjects.projects.01.link`),
    },
    {
      course: 'web',
      image: GetStudentProjectsImage('web', 1),
      name: t(`course-web-dev-campus:web.studentProjects.projects.02.title`),
      text: t(
        `course-web-dev-campus:web.studentProjects.projects.02.description`
      ),
      url: t(`course-web-dev-campus:web.studentProjects.projects.02.link`),
    },
    {
      course: 'uxui',
      image: GetStudentProjectsImage('uxui', 0),
      name: t(
        `course-ux-ui-design-campus:uxui.studentProjects.projects.01.title`
      ),
      text: t(
        `course-ux-ui-design-campus:uxui.studentProjects.projects.01.description`
      ),
      url: t(
        `course-ux-ui-design-campus:uxui.studentProjects.projects.01.link`
      ),
    },
    {
      course: 'uxui',
      image: GetStudentProjectsImage('uxui', 1),
      name: t(
        `course-ux-ui-design-campus:uxui.studentProjects.projects.02.title`
      ),
      text: t(
        `course-ux-ui-design-campus:uxui.studentProjects.projects.02.description`
      ),
      url: t(
        `course-ux-ui-design-campus:uxui.studentProjects.projects.02.link`
      ),
    },
    {
      course: 'data',
      image: GetStudentProjectsImage('data', 0),
      name: t(
        `course-data-analytics-campus:data.studentProjects.projects.01.title`
      ),
      text: t(
        `course-data-analytics-campus:data.studentProjects.projects.01.description`
      ),
      url: t(
        `course-data-analytics-campus:data.studentProjects.projects.01.link`
      ),
    },
    {
      course: 'data',
      image: GetStudentProjectsImage('data', 1),
      name: t(
        `course-data-analytics-campus:data.studentProjects.projects.02.title`
      ),
      text: t(
        `course-data-analytics-campus:data.studentProjects.projects.02.description`
      ),
      url: t(
        `course-data-analytics-campus:data.studentProjects.projects.02.link`
      ),
    },
  ];

  return (
    <>
      {userLocation?.country === 'NL' && <Banner type="stap" />}
      <Header pageName={pageName} showBackground>
        <PageTitle
          subtitle={t('alumni:alumni.mainHeadline.subtitle')}
          title={t('alumni:alumni.mainHeadline.title')}
        />
      </Header>
      <BlogArticles articles={alumniArticles} hideFilter locale={locale} />
      <StudentProjectsAlumni studentProjects={studentProjects} />
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query ($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(menu)|(seo)|(alumni)|(course-web-dev-campus)|(course-ux-ui-design-campus)|(course-data-analytics-campus)|(breadcrumbs)|(banner)|(blog)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next()(Alumni);
