import React, { useState } from 'react';
import uniqid from 'uniqid';
import classNames from 'classnames';
import Layout from '../atoms/layout';
import { Title1 } from '../atoms/headers';
import {
  Courses,
  StudentCards,
  Card,
  ButtonLink,
  Pagination,
  Swipeable,
  PaginationItems,
  CardIndex,
  LayoutStudentProjects
} from './styles';
import { emitTabCoursesEvent, emitViewDetailsEvent } from './ga_events';
import { isMobile } from '../../lib/utils';
import ArrowRight from '../../../public/assets/icons/arrows/arrow-right.svg';
import ArrowLeft from '../../../public/assets/icons/arrows/arrow-left.svg';
import { Text3 } from '../atoms/body';
import StudentProject from './interface';
interface Props {
  studentProjects: StudentProject[];
}
function StudentProjectsAlumni(props: Props) {
  const { studentProjects } = props;
  const [stateStudentProjects, setstateStudentProjects] = useState({
    course: 'web',
    pagination: 0
  });
  const { course, pagination } = stateStudentProjects;
  const cardsShown = isMobile() ? 1 : 4;

  const studentCardsFilter = studentProjects.filter(
    (project: StudentProject) => project.course === course
  );

  const changeCard = (num: number) => {
    const next = pagination + num;
    if (next < 0) {
      setstateStudentProjects({
        ...stateStudentProjects,
        pagination: studentCardsFilter.length - 1
      });
    } else if (next >= studentCardsFilter.length) {
      setstateStudentProjects({
        ...stateStudentProjects,
        pagination: 0
      });
    } else {
      setstateStudentProjects({
        ...stateStudentProjects,
        pagination: next
      });
    }
  };

  const paginationCreator = () => {
    const items = [];
    const length = isMobile()
      ? studentCardsFilter.length
      : Math.ceil(studentCardsFilter.length / 4);
    for (let i = 0; i < length; i++) {
      items.push(
        <CardIndex
          totalItems={studentCardsFilter.length}
          key={uniqid()}
          className={classNames({
            active: i === pagination
          })}
        />
      );
    }
    return items;
  };

  const renderCards = ({ image, url, name, text }: any) => (
    <Card background={image} key={uniqid()}>
      <div>
        <Text3>{name}</Text3>
        {text}
      </div>
      <ButtonLink
        onClick={emitViewDetailsEvent}
        href={url}
        target="_blank"
        rel="noreferrer noopener"
      >
        View Project Details
      </ButtonLink>
    </Card>
  );

  const studentCardsByCourse = () =>
    studentCardsFilter
      .slice(pagination, pagination + cardsShown)
      .map(renderCards);

  const changeCourse = (newCourse: string) => {
    setstateStudentProjects({
      course: newCourse,
      pagination: 0
    });
  };

  return (
    <Layout extend={LayoutStudentProjects}>
      <Title1 as="h2">Student Projects</Title1>
      <Courses>
        <h3
          onClick={() => {
            emitTabCoursesEvent('wd');
            changeCourse('web');
          }}
          className={classNames({ active: course === 'web' })}
        >
          Web Development
        </h3>
        <h3
          onClick={() => {
            emitTabCoursesEvent('ux');
            changeCourse('uxui');
          }}
          className={classNames({ active: course === 'uxui' })}
        >
          UX/UI Design
        </h3>
        <h3
          onClick={() => {
            emitTabCoursesEvent('da');
            changeCourse('data');
          }}
          className={classNames({ active: course === 'data' })}
        >
          Data Analytics
        </h3>
      </Courses>
      <StudentCards>{studentCardsByCourse()}</StudentCards>
      <Pagination>
        <PaginationItems>{paginationCreator()}</PaginationItems>
        <Swipeable>
          <ArrowLeft onClick={() => changeCard(-1)} />
          <ArrowRight onClick={() => changeCard(1)} />
        </Swipeable>
      </Pagination>
    </Layout>
  );
}

export default StudentProjectsAlumni;
